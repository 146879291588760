import * as three from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};
const stringBox = {
  wScene: 0,
  hScene: 0,
};
// Settings
const fontName = "Verdana";
const textureFontSize = 100;
const fontScaleFactor = 1;
// String to show
const string = "In progress";

// Canvas
const canvas = document.querySelector("#threejs-canvas");

// Scene
const scene = new three.Scene();
scene.background = new three.Color(0xf0eff4);

// Camera
const camera = new three.PerspectiveCamera(
  maprange(sizes.width, 390, 3000, 115, 30),
  sizes.width / sizes.height
);
camera.position.z = 400;
scene.add(camera);

// Renderer
const renderer = new three.WebGLRenderer({
  canvas: canvas,
  alpha: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

const textureLoader = new three.TextureLoader();
const textMatcapTexture = textureLoader.load("/assets/matcap4.png");
const particleGeometries = [
  new three.TorusKnotGeometry(2, 0.8),
  new three.TetrahedronGeometry(4),
  new three.DodecahedronGeometry(3),
  new three.BoxGeometry(4, 4, 4),
  new three.TorusGeometry(2, 1.6, 16, 32),
  new three.CylinderGeometry(2, 1, 4),
  new three.SphereGeometry(2),
];
let particleGeometriesIndex = 0;
const particleMaterial = new three.MeshMatcapMaterial({
  matcap: textMatcapTexture,
  color: 0xe5e8ff,
  transparent: true,
  opacity: 0.9,
  // wireframe: true,
});
let dummy = new three.Object3D();

// Create canvas to sample the text
const textCanvas = document.createElement("canvas");
const textCtx = textCanvas.getContext("2d");
// document.body.appendChild(textCanvas);

let textureCoordinates = [];
let particles = [];

const sampleCoordinates = () => {
  // Parse text
  const lines = string.split(`\n`);
  const linesMaxLength = [...lines].sort((a, b) => b.length - a.length)[0].length;
  const wTexture = textureFontSize * 0.7 * linesMaxLength;
  const hTexture = lines.length * textureFontSize;

  // Draw text
  const linesNumber = lines.length;
  textCanvas.width = wTexture;
  textCanvas.height = hTexture;
  textCtx.font = `100 ${textureFontSize}px ${fontName}`;
  textCtx.fillStyle = "#2a9df8";
  textCtx.clearRect(0, 0, textCanvas.width, textCanvas.height);

  for (let i = 0; i < linesNumber; i++) {
    textCtx.fillText(lines[i], 0, ((i + 0.8) * hTexture) / linesNumber);
  }

  // Sample coordinates
  textureCoordinates = [];
  const samplingStep = 4;
  if (wTexture > 0) {
    const imageData = textCtx.getImageData(0, 0, textCanvas.width, textCanvas.height);

    for (let i = 0; i < textCanvas.height; i += samplingStep) {
      for (let j = 0; j < textCanvas.width; j += samplingStep) {
        // Since our canvas doesn't have anything but colored text on the unset (transparent black)
        // background, we can check any of the four RGBA bytes with against a condition as simple
        // as bigger than zero.
        // Checking if R-channel is not zero since the background RGBA is (0,0,0,0)
        if (imageData.data[(j + i * textCanvas.width) * samplingStep] > 0) {
          textureCoordinates.push({ x: j, y: i });
        }
      }
    }
  }
};

const fixCoordinates = () => {
  const maxX = Math.max(...textureCoordinates.map((v) => v.x));
  const maxY = Math.max(...textureCoordinates.map((v) => v.y));
  stringBox.wScene = maxX;
  stringBox.hScene = maxY;
  textureCoordinates = textureCoordinates.map((c) => {
    return { x: c.x * fontScaleFactor, y: c.y * fontScaleFactor };
  });
};

let instancedMesh;
const createParticles = () => {
  instancedMesh = new three.InstancedMesh(
    particleGeometries[particleGeometriesIndex],
    particleMaterial,
    textureCoordinates.length
  );
  scene.add(instancedMesh);

  // centralize it in the same way as before
  instancedMesh.position.x = -0.5 * stringBox.wScene;
  instancedMesh.position.y = -0.5 * stringBox.hScene;

  particles = textureCoordinates.map(
    (c) => new Particle(c.x * fontScaleFactor, c.y * fontScaleFactor)
  );
};

class Particle {
  constructor(x, y) {
    this.x = x;
    this.y = y;
    this.z = 0;
    this.rotationX = Math.random() * 2 * Math.PI * 0.2;
    this.rotationY = Math.random() * 2 * Math.PI * 0.2;
    this.deltaRotation = 0.06 * (Math.random() - 0.2);
    this.animate = function () {
      this.rotationX += this.deltaRotation;
      this.rotationY += this.deltaRotation;
    };
  }
}
// Clock
const clock = new three.Clock();

function updateParticlesMatrices() {
  for (let i = 0; i < particles.length; i++) {
    const p = particles[i];
    p.animate(clock.getElapsedTime());
    dummy.rotation.set(p.rotationX, p.rotationY, p.rotationZ);
    dummy.position.set(p.x, stringBox.hScene - p.y, Math.random());

    dummy.updateMatrix();
    instancedMesh.setMatrixAt(i, dummy.matrix);
    const h = (Math.random() * (290 - 160) + 160).toFixed(0);
    const s = (Math.random() * (90 - 70) + 70).toFixed(0);
    const l = (Math.random() * (60 - 50) + 50).toFixed(0);
    instancedMesh.setColorAt(i, new three.Color(`hsl(${h}, ${s}%, ${l}%)`));
  }
  instancedMesh.instanceMatrix.needsUpdate = true;
}

function animateParticles() {
  const elapsedTime = clock.getElapsedTime();
  for (let i = 0; i < particles.length; i++) {
    const p = particles[i];
    p.animate(elapsedTime);
    dummy.rotation.set(p.rotationX, p.rotationY, 0);
    dummy.position.set(p.x, stringBox.hScene - p.y, p.z);

    dummy.updateMatrix();
    instancedMesh.setMatrixAt(i, dummy.matrix);
  }
  instancedMesh.instanceMatrix.needsUpdate = true;
}

window.addEventListener("pointerup", () => {
  instancedMesh.geometry =
    particleGeometries[++particleGeometriesIndex % particleGeometries.length];
});

function maprange(val, in_min, in_max, out_min, out_max) {
  return ((val - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}
// Handle window resize
window.addEventListener("resize", () => {
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  camera.aspect = sizes.width / sizes.height;
  camera.fov = maprange(sizes.width, 390, 3000, 115, 30);
  camera.updateProjectionMatrix();

  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

const tick = () => {
  controls.update();
  animateParticles();
  renderer.render(scene, camera);
  window.requestAnimationFrame(tick);
};

sampleCoordinates();
fixCoordinates();
createParticles();
updateParticlesMatrices();
tick();
